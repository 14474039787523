.App {
  display: block;
  margin: auto;
  padding: 18px;
  max-width: 700px;
  text-align: left;
  font-family: monospace;
  background-color: #eee;
  position: relative;
  margin-top: 10px;
}

body{
  background-color:#eee;
}


.posts{
  list-style-type: circle;
  font-size:110%
}

.postsList{
  display: block;
  text-align: left;
  font-family: monospace;
}

h2{
  font-weight: bold;
}

.list{
  display: block;
}

.listofpost{
  font-weight: bold;
}

.navbar{
  text-align: right;
}

hr{
  border-style:dashed;
}

a{
  font-style: italic;
}